input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.tooltip {
  font-size: 14px !important;
  font-family: Lato !important;
  pointer-events: auto !important;
  color: #ffffff !important;
  background-color: #00205c !important;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5) !important;
}

.blank-border {
  --tags-border-color: white !important
}
